import { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  CreateFunctionResponse,
  GetAdaptiveContentResponse,
  GetFunctionExternalIdResponse,
  IAdaptiveContentAdapter,
} from './types';
import { ONE_SECOND_IN_MS } from '../../constants';

class AdaptiveContentAdapter implements IAdaptiveContentAdapter {
  constructor(private httpClient: IHttpClient) {}

  async getAdaptiveContent(
    compId: string,
    originalContent: string,
    timeout?: number,
  ): Promise<string> {
    const response = await this.httpClient.post<GetAdaptiveContentResponse>(
      '/_api/adaptive-component/v1/adaptive/content',
      {
        original_content: originalContent,
        componentId: compId,
      },
      {
        headers: {
          'X-Time-Budget': timeout ?? ONE_SECOND_IN_MS * 5,
        },
      },
    );

    const { content } = response.data.content;
    if (!content) {
      throw new Error('Adaptive content not found');
    }

    return content;
  }

  async getFunctionExternalId(compId: string): Promise<string> {
    const response = await this.httpClient.get<GetFunctionExternalIdResponse>(
      '/_api/adaptive-component/v1/functions/external-id',
      {
        params: {
          component_id: compId,
        },
      },
    );

    const { externalId } = response.data;
    if (!externalId) {
      throw new Error('Function external id not found');
    }

    return externalId;
  }

  async createFunction(compId: string): Promise<string> {
    const response = await this.httpClient.post<CreateFunctionResponse>(
      '/_api/adaptive-component/v1/functions',
      {
        component_id: compId,
      },
    );
    return response.data.implementerConfigId;
  }

  async triggerFunctionsCleanup(): Promise<void> {
    await this.httpClient.post('/_api/adaptive-component/v1/functions/clean');
  }
}

export const buildAdaptiveContentAdapter = (
  httpClient: IHttpClient,
): IAdaptiveContentAdapter => new AdaptiveContentAdapter(httpClient);
