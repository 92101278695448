import { IAdaptiveContentService } from './types';
import { IEditorService } from '../editor/types';
import { IAdaptiveContentAdapter } from '../../adapter/adaptiveContent/types';
import { runWithRetry } from '../../utils/runWithRetry';

class AdaptiveContentService implements IAdaptiveContentService {
  constructor(private readonly adaptiveContentAPI: IAdaptiveContentAdapter) {}

  async attachFunction(
    compId: string,
    editorService: IEditorService,
  ): Promise<string> {
    await editorService.openProgressBar({
      stepTitle: 'Creating new Function...',
      currentStep: 0,
      totalSteps: 2,
    });
    try {
      const response = await runWithRetry(
        () => this.adaptiveContentAPI.createFunction(compId),
        {
          attempts: 2,
          delayInMs: 500,
          shouldIncrementDelay: false,
        },
      );
      await editorService.updateProgressBar(
        'Linking Function to Component...',
        1,
      );
      if (!response) {
        throw new Error('Failed to attach function to component');
      }
      await editorService.closeProgressBar(false);
      return response;
    } catch (e) {
      await editorService.showFailedToAttachFunctionError();
      await editorService.closeProgressBar(false);
      throw e;
    }
  }

  async getFunctionExternalId(compId: string): Promise<string> {
    return this.adaptiveContentAPI.getFunctionExternalId(compId);
  }

  async triggerFunctionCleanup(): Promise<void> {
    return this.adaptiveContentAPI.triggerFunctionsCleanup();
  }

  async getAdaptiveContent(
    compId: string,
    originalContent: string,
    timeout?: number,
  ): Promise<string> {
    return this.adaptiveContentAPI.getAdaptiveContent(
      compId,
      originalContent,
      timeout,
    );
  }
}

export const buildAdaptiveContentService = (
  adaptiveContentAPI: IAdaptiveContentAdapter,
): IAdaptiveContentService => new AdaptiveContentService(adaptiveContentAPI);
