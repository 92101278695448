import {
  ComponentDefinition,
  ComponentRef,
  EditorSDK,
} from '@wix/platform-editor-sdk';
import { IEditorService, OpenProgressBarParams } from './types';
import { TOKEN } from '../../constants';

class EditorService implements IEditorService {
  constructor(private editorSDK: EditorSDK) {}

  async addComponent(
    componentDefinition: ComponentDefinition,
  ): Promise<ComponentRef> {
    const pageRef = await this.editorSDK.pages.getCurrent(TOKEN);

    return this.editorSDK.document.components.add(TOKEN, {
      componentDefinition,
      pageRef,
    });
  }

  async removeComponent(componentRef: ComponentRef): Promise<void> {
    await this.editorSDK.document.components.remove(TOKEN, {
      componentRef,
    });
  }

  async openProgressBar({
    stepTitle,
    currentStep,
    totalSteps,
  }: OpenProgressBarParams): Promise<void> {
    await this.editorSDK.editor.openProgressBar(TOKEN, {
      title: 'Setting up Logic Workspace',
      stepTitle,
      currentStep,
      totalSteps,
    });
  }

  async updateProgressBar(
    stepTitle: string,
    currentStep: number,
  ): Promise<void> {
    await this.editorSDK.editor.updateProgressBar(TOKEN, {
      stepTitle,
      currentStep,
    });
  }

  async closeProgressBar(isError: boolean): Promise<void> {
    await this.editorSDK.editor.closeProgressBar(TOKEN, { isError });
  }

  async getFirstSelectedComponent() {
    const comps = await this.editorSDK.selection.getSelectedComponents(TOKEN);
    return comps[0];
  }

  async openBusinessManager(url: string) {
    await this.editorSDK.editor.openDashboardPanel(TOKEN, {
      url,
      closeOtherPanels: true,
    });
  }
  async showMissingFunctionError() {
    return this.editorSDK.editor.openErrorPanel(TOKEN, {
      headerText: 'Something went wrong',
      topDescriptionText:
        'Some logic is missing. Either your Function was deleted or technically something went wrong. Create a new Function or wait a few minutes and try again.',
      shouldShowIllustration: false,
      mainActionText: 'Create New Function',
    });
  }

  async showFailedToAttachFunctionError() {
    return this.editorSDK.editor.openErrorPanel(TOKEN, {
      headerText: 'Failed to create Function',
      topDescriptionText:
        'We were unable to create the function for this component. Please try again later.',
      shouldShowIllustration: false,
      mainActionText: 'Close',
      secondaryActionText: '',
    });
  }
}

export const buildEditorService = (editorSDK: EditorSDK) => {
  return new EditorService(editorSDK);
};
