interface RetryConfig {
  attempts?: number;
  delayInMs?: number;
  shouldIncrementDelay?: boolean;
}

const DEFAULT_RETRY_CONFIG: Required<RetryConfig> = {
  attempts: 3,
  delayInMs: 1000,
  shouldIncrementDelay: true,
};

async function waitInMs(currentDelay: number) {
  await new Promise((resolve) => setTimeout(resolve, currentDelay));
}

function increaseDelay(currentAttempt: number, delayTime: number) {
  return currentAttempt * delayTime;
}

/*
 This function runs the provided operation with retries in case of failure.
 The delay increases with each attempt by the current attempt number multiplied by the initial delay time.
 Since we run in the editor, we do not want to throw errors.
 */
export async function runWithRetry<T>(
  operation: () => Promise<T>,
  config: RetryConfig = DEFAULT_RETRY_CONFIG,
): Promise<T | undefined> {
  const { attempts, shouldIncrementDelay } = {
    ...DEFAULT_RETRY_CONFIG,
    ...config,
  };
  const initialDelay = config.delayInMs || DEFAULT_RETRY_CONFIG.delayInMs;

  let currentDelay = initialDelay;
  let currentAttempt = 1;

  while (currentAttempt <= attempts) {
    try {
      return operation();
    } catch (error) {
      await waitInMs(currentDelay);
      currentDelay = shouldIncrementDelay
        ? increaseDelay(currentAttempt, initialDelay)
        : currentDelay;
      currentAttempt++;
    }
  }
}
