import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { buildAdaptiveContentAdapter } from '../../adapter/adaptiveContent/adaptiveContent.adapter';
import { buildEditorService } from '../../services/editor/editor.service';
import { buildAdaptiveContentService } from '../../services/adaptiveContent/adaptiveContent.service';
import { handleManageLogicClick } from './utils';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const { httpClient } = flowAPI;
  const adaptiveContentAdapter = buildAdaptiveContentAdapter(httpClient);
  const editorService = buildEditorService(editorSDK);
  const adaptiveContentService = buildAdaptiveContentService(
    adaptiveContentAdapter,
  );

  widgetBuilder.set({
    displayName: 'AdaptiveContentWidget',
    nickname: 'AdaptiveContentWidget',
  });

  return widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: 'Manage Logic',
      onClick: async () => {
        return handleManageLogicClick(adaptiveContentService, editorService);
      },
    })
    .set('mainAction2', {
      behavior: 'HIDE',
    })
    .set('design', {
      behavior: 'HIDE',
    })
    .set('animation', {
      behavior: 'HIDE',
    });
};
