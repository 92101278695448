import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { buildAdaptiveContentAdapter } from './adapter/adaptiveContent/adaptiveContent.adapter';
import { buildEditorService } from './services/editor/editor.service';
import { buildAdaptiveContentService } from './services/adaptiveContent/adaptiveContent.service';
import { ADAPTIVE_CONTENT_WIDGET_OPTIONS } from './constants';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  const { httpClient } = flowAPI;
  const adaptiveContentAdapter = buildAdaptiveContentAdapter(httpClient);
  const editorService = buildEditorService(editorSDK);
  const adaptiveContentService = buildAdaptiveContentService(
    adaptiveContentAdapter,
  );

  if (options.firstInstall) {
    const compRef = await editorSDK.application.appStudioWidgets.addWidget(
      '',
      ADAPTIVE_CONTENT_WIDGET_OPTIONS,
    );
    await adaptiveContentService.attachFunction(compRef.id, editorService);
  }

  await editorSDK.addEventListener('widgetAdded', async (event) => {
    const componentRef = event.detail.componentRef;
    const compId = componentRef.id;

    try {
      return adaptiveContentService.attachFunction(compId, editorService);
    } catch (e) {
      return editorService.removeComponent(componentRef);
    }
  });

  // await editorSDK.addEventListener('componentAddedToStage', async (event) => {
  //   const compRef = event.detail.compRef;
  //   await editorSDK.application.appStudioWidgets.props.set('', {
  //     widgetRef: compRef,
  //     newProps: {
  //       compId: compRef.id,
  //     },
  //   });
  // });

  await editorSDK.addEventListener('siteWasPublished', async () => {
    return adaptiveContentService.triggerFunctionCleanup();
  });
};
