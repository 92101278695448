import { buildAdaptiveContentAdapter } from '../../adapter/adaptiveContent/adaptiveContent.adapter';
import { ONE_SECOND_IN_MS } from '../../constants';
import { buildAdaptiveContentService } from '../../services/adaptiveContent/adaptiveContent.service';
import model from './model';

export default model.createController(
  ({ $bind, $widget, flowAPI, controllerConfig, $w, widgetConfig }) => {
    return {
      pageReady: async () => {
        const { httpClient } = flowAPI;
        const { compId } = controllerConfig;

        const adaptiveContentAdapter = buildAdaptiveContentAdapter(httpClient);
        const adaptiveContentService = buildAdaptiveContentService(
          adaptiveContentAdapter,
        );

        // const externalId = await adaptiveContentService
        //   .getFunctionExternalId(compId)
        //   .then((res) => res)
        //   .catch(() => '');

        // const isInEditor = flowAPI.environment.isEditor;

        // if (isInEditor && externalId === '') {
        //   // $w('#box1').style.backgroundColor = 'rgb(255, 0, 0)';
        //   $w('#box1').style.borderColor = 'rgb(255, 0, 0)';
        //   $w('#box1').style.borderRadius = '50px';
        //   $w('#box1').style.borderWidth = '50px';
        // }

        // if (externalId !== '') {
        //   $w('#box1').style.backgroundColor = 'rgb(255, 255, 255)';
        //   $w('#box1').style.borderColor = 'rgb(199, 199, 199)';
        // }
        // const boxStyle = $w('#box1').style;

        if (
          flowAPI.environment.isSSR ||
          flowAPI.environment.isPreview ||
          flowAPI.environment.isViewer
        ) {
          const originalContent = $w('#text1').text;

          $w('#text1').text = await adaptiveContentService.getAdaptiveContent(
            compId,
            originalContent,
            flowAPI.environment.isSSR
              ? ONE_SECOND_IN_MS
              : ONE_SECOND_IN_MS * 10,
          );
        }
      },
      exports: {},
    };
  },
);
