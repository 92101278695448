import { addWidgetOptions } from '@wix/platform-editor-sdk';

export const TOKEN = 'token';
export const ONE_SECOND_IN_MS = 1000;

export const ADAPTIVE_CONTENT_WIDGET_PRESETS = {
  Desktop: 'variants-lgnn37aw',
};

export const ADAPTIVE_CONTENT_WIDGET_OPTIONS: addWidgetOptions = {
  widgetId: 'f3c60257-3719-4d46-801b-1551e4cf4984',
  installationType: 'open',
  layout: {
    height: 200,
    width: 450,
    x: 265,
    y: 144,
  },
  scopedPresets: {
    desktop: {
      layout: ADAPTIVE_CONTENT_WIDGET_PRESETS.Desktop,
      style: ADAPTIVE_CONTENT_WIDGET_PRESETS.Desktop,
    },
  },
  layouts: {
    componentLayout: {
      minHeight: {
        type: 'px',
        value: 280,
      },
      hidden: false,
      height: {
        type: 'auto',
      },
      type: 'ComponentLayout',
      width: {
        type: 'auto',
      },
    },
    itemLayout: {
      id: '',
      alignSelf: 'center',
      gridArea: {
        rowStart: 1,
        rowEnd: 2,
        columnStart: 1,
        columnEnd: 2,
      },
      justifySelf: 'center',
      type: 'GridItemLayout',
    },
  },
};
