import { IEditorService } from '../../services/editor/types';
import { IAdaptiveContentService } from '../../services/adaptiveContent/types';
import { PanelResolveType } from '@wix/platform-editor-sdk';

const openBusinessManagerForFunction = async (
  editorService: IEditorService,
  functionExternalId: string,
): Promise<void> => {
  const url = `/functions/edit/${functionExternalId}?referralInfo=adaptive-content-widget`;
  await editorService.openBusinessManager(url);
};

function createNewFunction(
  adaptiveContentService: IAdaptiveContentService,
  componentId: string,
  editorService: IEditorService,
) {
  try {
    return adaptiveContentService.attachFunction(componentId, editorService);
  } catch {
    return editorService.showFailedToAttachFunctionError();
  }
}

const getOrCreateFunctionExternalId = async (
  adaptiveContentService: IAdaptiveContentService,
  editorService: IEditorService,
  componentId: string,
): Promise<string> => {
  try {
    // For some reason, without await the "Missing Function" modal will not show
    return await adaptiveContentService.getFunctionExternalId(componentId);
  } catch (e) {
    const userResolvedAction = await editorService.showMissingFunctionError();
    const isCreateNewFunctionChosen =
      userResolvedAction === PanelResolveType.MAIN_ACTION;

    if (isCreateNewFunctionChosen) {
      return createNewFunction(
        adaptiveContentService,
        componentId,
        editorService,
      );
    }
    throw e;
  }
};

export const handleManageLogicClick = async (
  adaptiveContentService: IAdaptiveContentService,
  editorService: IEditorService,
) => {
  try {
    const component = await editorService.getFirstSelectedComponent();
    const functionExternalId = await getOrCreateFunctionExternalId(
      adaptiveContentService,
      editorService,
      component.id,
    );

    return openBusinessManagerForFunction(editorService, functionExternalId);
  } catch (error) {
    return editorService.showFailedToAttachFunctionError();
  }
};
